<template>
  <div id="app">
    <div id='step1' v-if='currentStep == 1'>
      <img src="../images/logo.png" alt="" class='logo'>
      <h1 class="main-title">Сделай коллегам комплимент</h1>
      <h2>Календарь 2025</h2>
      <clipper-upload v-model="photo" class='uploadBtn' @input='loadPhoto'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2030_416)">
              <path d="M11.0068 2.57812L10.9998 18.0161C10.9998 18.2813 11.1052 18.5357 11.2927 18.7232C11.4803 18.9108 11.7346 19.0161 11.9998 19.0161C12.265 19.0161 12.5194 18.9108 12.7069 18.7232C12.8945 18.5357 12.9998 18.2813 12.9998 18.0161L13.0068 2.59512L15.9188 5.50812C16.1064 5.69559 16.3607 5.8009 16.6258 5.8009C16.891 5.8009 17.1453 5.69559 17.3328 5.50812C17.5203 5.32059 17.6256 5.06628 17.6256 4.80112C17.6256 4.53595 17.5203 4.28164 17.3328 4.09412L14.1218 0.879115C13.8432 0.600336 13.5124 0.379186 13.1483 0.228301C12.7842 0.0774169 12.394 -0.000244141 11.9998 -0.000244141C11.6057 -0.000244141 11.2154 0.0774169 10.8513 0.228301C10.4872 0.379186 10.1564 0.600336 9.87783 0.879115L6.66682 4.09112C6.47935 4.27864 6.37404 4.53295 6.37404 4.79812C6.37404 5.06328 6.47935 5.31759 6.66682 5.50512C6.85435 5.69259 7.10866 5.7979 7.37383 5.7979C7.63899 5.7979 7.8933 5.69259 8.08083 5.50512L11.0068 2.57812Z" fill="currentColor"/>
              <path d="M22 16.9995V20.9995C22 21.2647 21.8946 21.5191 21.7071 21.7066C21.5196 21.8941 21.2652 21.9995 21 21.9995H2.99999C2.73478 21.9995 2.48042 21.8941 2.29289 21.7066C2.10535 21.5191 1.99999 21.2647 1.99999 20.9995V16.9995C1.99999 16.7343 1.89464 16.4799 1.7071 16.2924C1.51956 16.1049 1.26521 15.9995 0.999992 15.9995C0.734776 15.9995 0.480422 16.1049 0.292886 16.2924C0.105349 16.4799 -7.62939e-06 16.7343 -7.62939e-06 16.9995L-7.62939e-06 20.9995C-7.62939e-06 21.7952 0.316063 22.5582 0.878672 23.1208C1.44128 23.6834 2.20434 23.9995 2.99999 23.9995H21C21.7956 23.9995 22.5587 23.6834 23.1213 23.1208C23.6839 22.5582 24 21.7952 24 20.9995V16.9995C24 16.7343 23.8946 16.4799 23.7071 16.2924C23.5196 16.1049 23.2652 15.9995 23 15.9995C22.7348 15.9995 22.4804 16.1049 22.2929 16.2924C22.1053 16.4799 22 16.7343 22 16.9995Z" fill="currentColor"/>
            </g>
            <defs>
              <clipPath id="clip0_2030_416">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        Загрузить фото
      </clipper-upload>
      <a class="prevBtn" href="compl">
        <img src="../images/prevBtn.svg" alt="">
        Назад
      </a>
    </div>
    <div id='step2' v-if='currentStep == 2'>
      <div class='header'>
        <div>
          <img src="../images/logo.png" alt="" class='logo' @click='repeat'>
        </div>
        <a @click.prevent='showInstruction'>
          <img src='../images/help.svg' class='help' @click='showInstruction'>
        </a>
      </div>
      <div>
        <h1 class="main-title">Генератор&nbsp;календаря</h1>
      </div>
      <div class='template'>
        <img src='../images/template.png' alt="">
        <clipper-fixed ref="clipper" :src="photo" :rotate=rotate :wrap-ratio='1.408' :ratio='1.408' :area='100' :bg-color="'rgba(217, 240, 234, 0)'" :border='0'></clipper-fixed>
      </div>
      <template v-if='!creating'>
        <div class='rotate-buttons' v-show='!creating'>
          <a class='rotate-btn left' @click.prevent='rotateImage(-90)' title='Повернуть против часовой стрелки'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2030_473)">
                <path d="M2.03811 12.875C2.23093 15.1548 3.19742 17.3002 4.77721 18.9552C6.357 20.6102 8.45514 21.6754 10.7235 21.9739C12.9919 22.2725 15.2942 21.7866 17.2485 20.5967C19.2027 19.4069 20.6914 17.5846 21.4676 15.4324C22.2438 13.2801 22.2608 10.9271 21.5158 8.76383C20.7708 6.60055 19.3086 4.75699 17.3718 3.53901C15.435 2.32103 13.14 1.80184 10.8675 2.06758C8.595 2.33332 6.48167 3.36801 4.87811 5H8.00011C8.26533 5 8.51968 5.10536 8.70722 5.29289C8.89475 5.48043 9.00011 5.73478 9.00011 6C9.00011 6.26522 8.89475 6.51957 8.70722 6.70711C8.51968 6.89464 8.26533 7 8.00011 7H3.85711C3.36469 6.99974 2.8925 6.804 2.54431 6.45581C2.19611 6.10761 2.00038 5.63543 2.00011 5.143V1C2.00011 0.734784 2.10547 0.48043 2.293 0.292893C2.48054 0.105357 2.73489 0 3.00011 0V0C3.26533 0 3.51968 0.105357 3.70722 0.292893C3.89475 0.48043 4.00011 0.734784 4.00011 1V3.078C6.01143 1.28073 8.57022 0.214484 11.2626 0.0517113C13.9551 -0.111061 16.6237 0.639157 18.8369 2.18103C21.0501 3.72291 22.6784 5.96628 23.4586 8.54832C24.2388 11.1304 24.1252 13.9001 23.1361 16.4095C22.147 18.919 20.3404 21.0214 18.0083 22.3768C15.6763 23.7322 12.9552 24.2614 10.2851 23.8786C7.6151 23.4958 5.15225 22.2236 3.29491 20.2676C1.43756 18.3116 0.294329 15.7863 0.0501099 13.1C0.0371933 12.9607 0.0534267 12.8203 0.0977745 12.6877C0.142122 12.555 0.213612 12.4331 0.307688 12.3296C0.401764 12.2261 0.516365 12.1434 0.644188 12.0866C0.772011 12.0299 0.910255 12.0004 1.05011 12C1.29406 11.9971 1.53037 12.085 1.71301 12.2468C1.89565 12.4085 2.01155 12.6325 2.03811 12.875Z" fill="currentColor"/>
              </g>
              <defs>
                <clipPath id="clip0_2030_473">
                <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)"/>
                </clipPath>
              </defs>
            </svg>
          </a>
          <a class='rotate-btn right' @click.prevent='rotateImage(90)' title='Повернуть по часовой стрелке'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2030_473)">
                <path d="M2.03811 12.875C2.23093 15.1548 3.19742 17.3002 4.77721 18.9552C6.357 20.6102 8.45514 21.6754 10.7235 21.9739C12.9919 22.2725 15.2942 21.7866 17.2485 20.5967C19.2027 19.4069 20.6914 17.5846 21.4676 15.4324C22.2438 13.2801 22.2608 10.9271 21.5158 8.76383C20.7708 6.60055 19.3086 4.75699 17.3718 3.53901C15.435 2.32103 13.14 1.80184 10.8675 2.06758C8.595 2.33332 6.48167 3.36801 4.87811 5H8.00011C8.26533 5 8.51968 5.10536 8.70722 5.29289C8.89475 5.48043 9.00011 5.73478 9.00011 6C9.00011 6.26522 8.89475 6.51957 8.70722 6.70711C8.51968 6.89464 8.26533 7 8.00011 7H3.85711C3.36469 6.99974 2.8925 6.804 2.54431 6.45581C2.19611 6.10761 2.00038 5.63543 2.00011 5.143V1C2.00011 0.734784 2.10547 0.48043 2.293 0.292893C2.48054 0.105357 2.73489 0 3.00011 0V0C3.26533 0 3.51968 0.105357 3.70722 0.292893C3.89475 0.48043 4.00011 0.734784 4.00011 1V3.078C6.01143 1.28073 8.57022 0.214484 11.2626 0.0517113C13.9551 -0.111061 16.6237 0.639157 18.8369 2.18103C21.0501 3.72291 22.6784 5.96628 23.4586 8.54832C24.2388 11.1304 24.1252 13.9001 23.1361 16.4095C22.147 18.919 20.3404 21.0214 18.0083 22.3768C15.6763 23.7322 12.9552 24.2614 10.2851 23.8786C7.6151 23.4958 5.15225 22.2236 3.29491 20.2676C1.43756 18.3116 0.294329 15.7863 0.0501099 13.1C0.0371933 12.9607 0.0534267 12.8203 0.0977745 12.6877C0.142122 12.555 0.213612 12.4331 0.307688 12.3296C0.401764 12.2261 0.516365 12.1434 0.644188 12.0866C0.772011 12.0299 0.910255 12.0004 1.05011 12C1.29406 11.9971 1.53037 12.085 1.71301 12.2468C1.89565 12.4085 2.01155 12.6325 2.03811 12.875Z" fill="currentColor"/>
              </g>
              <defs>
                <clipPath id="clip0_2030_473">
                <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)"/>
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div class='buttons'>
          <button class='btn' @click.prevent='done' v-if='!creating'>Готово</button>
          <button class='btn' disabled v-else>Создание...</button>

          <clipper-upload v-model="photo" class='btn' @input='loadPhoto' v-show='!creating'>
            Выбрать другое фото
          </clipper-upload>
        </div>
      </template>

      <div class='preloader preloader--gradient' v-else>
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
      </div>
      <button type="button" class="prevBtn" @click.prevent='prevStep'>
        <img src="../images/prevBtn.svg" alt="">
        Назад
      </button>
    </div>
    <div id='step3' v-if='currentStep == 3'>
      <div class='header'>
        <div>
          <img src="../images/logo.png" alt="" class='logo' @click='repeat'>
        </div>
        <a @click.prevent='showInstruction'>
          <img src='../images/help.svg' class='help' @click='showInstruction'>
        </a>
      </div>
      <div>
        <h1 class="main-title">Генератор&nbsp;календаря</h1>
      </div>
      <div class='content'>
        <h3>Ваш календарь успешно создан!</h3>
        <viewer :images='[result]' :options='viewerOptions'>
          <img v-for="src in [result]" :key="src" :src="src" class='result'>
        </viewer>
        <div class='buttons'>
          <a @click.prevent='showPrintInstruction' class="print-instruction-btn">
            Инструкция для печати <img src='../images/help.svg' class='help' @click='showPrintInstruction'>
          </a>
          <a class='btn' @click.prevent='download'>Скачать</a>
          <button class='btn' v-if="webShareApiSupported" @click.prevent='shareResult'>Поделиться с коллегами<sup>*</sup></button>
          <a href='#' @click.prevent='repeat' class='repeat'>Сделать ещё</a>
        </div>

        <div class='hint' v-if="webShareApiSupported">
          <sup>*</sup> Для сохранения высокого качества изображения рекомендуется делиться через электронную почту либо скачать изображение и отправить его без сжатия любым удобным способом.
        </div>
      </div>
    </div>
    <modal name="instruction" :adaptive='true' :scrollable='true' :height="'auto'">
      <a href='#' class='modal-close' @click='hideInstruction'>&times;</a>
      <div class='modal-content'>
        <center><span class="modal-title">Инструкция</span></center>
        <p>Данный генератор позволяет создать календарь с Вашим фото на 2025 год.</p>
        <ol>
          <li>Загрузите либо сделайте фото (для мобильных устройств), нажав кнопку <b>&laquo;Загрузить фото&raquo;</b></li>
          <li>
            Отредактируйте положение фото: двигайте, приближайте и&nbsp;отдаляйте так, как считаете нужным.
            <br><i>Для стационарных устройств&nbsp;&mdash; перетаскивание и&nbsp;прокрутка, для мобильных&nbsp;&mdash; перетаскивание и&nbsp;сведение/разведение пальцев.</i>
            <br>Для поворота используйте соответствующие кнопки.
            <br>Можете выбрать другое фото, нажав на&nbsp;кнопку <b>&laquo;Выбрать другое фото&raquo;</b>.
          </li>
          <li>Для завершения нажмите кнопку <b>&laquo;Готово&raquo;</b>.</li>
          <li>Через некоторое время&nbsp;Вы увидите результат, который можно увеличить, нажав на него.</li>
          <li>Вы&nbsp;можете скачать результат, нажав на&nbsp;кнопку <b>&laquo;Скачать&raquo;</b>.</li>
          <li>Также Вы&nbsp;можете поделиться результатом с&nbsp;коллегами и&nbsp;подарить им&nbsp;праздничное настроение уже сейчас, нажав на&nbsp;кнопку <b>&laquo;Поделиться с&nbsp;коллегами&raquo;</b> <i>(Доступно для устройств, поддерживающих такую возможность)</i>.</li>
          <li>Если хотите пересоздать календарь или создать ещё один, нажмите на&nbsp;ссылку <b>&laquo;Сделать ещё&raquo;</b></li>
        </ol>
        <center>
          <b>
            <p>Порадуйте себя и своих коллег в 2025 году!</p>
          </b>
        </center>
        <a href='#' @click='hideInstruction'>Понятно, приступим!</a>
      </div>
    </modal>

    <modal name="printInstruction" :adaptive='true' :scrollable='true' :height="'auto'">
      <a href='#' class='modal-close' @click='hidePrintInstruction'>&times;</a>
      <div class='modal-content'>
        <center><span class="modal-title">Инструкция для печати</span></center>
        <p><strong><u>ПК:</u></strong> Сохраните календарь себе на&nbsp;устройство. Откройте изображение в&nbsp;удобном для вас редакторе, отправьте на&nbsp;печать. Либо выберите пункт &laquo;Печать&raquo; в&nbsp;меню файла.</p>
        <p><strong><u>Мобильные устройства:</u></strong> Чтобы сохранить качество изображения, сгенерируйте календарь в&nbsp;сервисе, затем сохраните его на&nbsp;своем устройстве и&nbsp;отправьте на&nbsp;печать. Размер такого изображения 3508*2480&nbsp;px. Используйте именно этот метод для сохранения четкости календаря при печати.</p>
        <p>Также календарем можно поделиться через мессенджеры. Затем сохранить изображение с&nbsp;мессенджера на&nbsp;свое устройство и&nbsp;отправить на&nbsp;печать. При этом качество изображения будет хуже. Размер изображения 1280*905&nbsp;px. При печати таким методом качество изображения теряется.</p>
        <center>
          <b>
            <p>Порадуйте себя и своих коллег в 2025 году!</p>
          </b>
        </center>
        <a href='#' @click='hidePrintInstruction'>Понятно, приступим!</a>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import downloadjs from 'downloadjs';
import 'normalize.css';
import { clipperFixed, clipperUpload, clipperRange } from 'vuejs-clipper';
import "vuejs-clipper/dist/vuejs-clipper.css";
import 'viewerjs/dist/viewer.css';
import 'vue-js-modal/dist/styles.css';

export default {
  components: {
    clipperFixed,
    clipperUpload,
    clipperRange
  },
  data: function () {
    return {
      currentStep: 1,
      photo: null,
      rotate: 0,
      creating: false,
      result: null,
      viewerOptions: { "inline": false, "button": true, "navbar": false, "title": false, "toolbar": false, "tooltip": false, "movable": false, "zoomable": true, "rotatable": false, "scalable": false, "transition": false, "fullscreen": false, "keyboard": false }
    }
  },
  created: function() {
    axios.defaults.headers.common["X-CSRF-Token"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  },
  methods: {
    prevStep: function(_event) {
      this.currentStep -= 1;
    },
    loadPhoto: function(_event) {
      this.currentStep = 2;
      if (this.$metrika) this.$metrika.reachGoal('UPLOAD_CLD');
    },
    done: function() {
      let $this  = this;
      // const canvas = this.$refs.clipper.clip();//call component's clip method
      // this.resultURL = canvas.toDataURL("image/jpeg", 1);

      this.creating = true;
      document.querySelector('.template').style.pointerEvents = 'none';

      if (this.$metrika) this.$metrika.reachGoal('START_CREATE_CLD');

      axios.post('/create_cld', {
        image: this.$refs.clipper.clip().toDataURL("image/png", 1)
      })
      .then(function(response) {
        $this.result = response.data.image;

        $this.currentStep = 3;
  
        if ($this.$metrika) $this.$metrika.reachGoal('CREATED_CLD');
      })
      .finally(() => {
        $this.creating = false;
        document.querySelector('.content').style.pointerEvents = 'unset';
      });
    },
    shareResult: async function() {
      const blob = await (await fetch(this.result)).blob();
      const file = new File([blob], 'calendar2025.jpg', { type: blob.type });
      navigator.share({
        title: 'Уважаемые коллеги, от души поздравляем вас с предстоящими праздниками! Создайте свой поздравительный календарь по ссылке https://bnphrmgroup.ru/cld. Сделайте приятно своим близким!',
        // url: 'https://bnphrmgroup.ru',
        files: [file],
      })
      if (this.$metrika) this.$metrika.reachGoal('START_SHARE_CLD');
    },
    repeat: function() {
      this.currentStep = 1;
      this.photo = null;
      this.rotate = 0;
      this.creating = false;
      this.result = null;
      if (this.$metrika) this.$metrika.reachGoal('REPEAT_CLD');
    },
    showInstruction() {
      this.$modal.show('instruction');
    },
    hideInstruction() {
      this.$modal.hide('instruction')
    },

    showPrintInstruction() {
      this.$modal.show('printInstruction');
    },
    hidePrintInstruction() {
      this.$modal.hide('printInstruction')
    },

    rotateImage(angle) {
      this.rotate += angle;
    },
    download() {
      if (this.$metrika) this.$metrika.reachGoal('DOWNLOAD_CLD');
      if (this.result) {
        downloadjs(this.result, 'Календарь 2025.png', 'image/png');
      }
    }
  },
  computed: {
    webShareApiSupported: function() {
      return navigator.canShare
    }
  }
  
}
</script>

<style scoped lang='scss'>
#app {
  font-family: Gilroy;
  height: 100%;
}

h1, h2 {
    margin: 0;
  }

  h2 {
    font-size: 50px;
    line-height: 59px;
    color: #1758A8;

    @media (max-width: 992px) {
      font-size: 24px;
    }
  }

  h1 {
    font-size: 64px;
    line-height: 78px;

    @media (max-width: 992px) {
      font-size: 30px;
      line-height: 38px;
    }
  }

#step1 {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
  padding: 135px 50px 50px;

  @media (max-width: 992px) {
    padding: 20px 20px 50px;
    height: unset;
  }

  .logo {
    width: 258px;
    // height: 159px;
    padding-bottom: 60px;

    @media (max-width: 992px) {
      width: 189px;
      // height: 113px;
    }
  }

  .main-title {
    margin-bottom: 90px;

    @media (max-width: 992px) {
      margin-bottom: 35px;
    }
  }

  .uploadBtn {
    margin-top: 69px;

    width: 311px;
    height: 78.5px;

    background: #FFFFFF;
    border: 2px solid #4A7EBD;
    box-sizing: border-box;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;

    text-align: center;

    color: #4A7EBD;

    cursor: pointer;

    transition: color 0.5s ease-out, background-color 0.5s ease-out;
    
    svg {
      margin-right: 20px;
      transition: color 0.5s ease-out;
    }

    &:hover,
    &:focus-visible {
      background-color: #4A7EBD;
      color: white;
    }

    @media (max-width: 992px) {
      margin-top: 30px;

      width: 228px;
      height: 72px;

      font-size: 20px;
      line-height: 26px;
    }
  }
}

.prevBtn {
  position: absolute;
  right: 5%;
  bottom: 5%;
  padding: 15px 35px;
  margin: 0;
  border: 0;
  background-color: #D9D9D9;
  display: flex;
  gap: 8px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 100px;
  text-decoration: none;
  color: black;

  @media (max-width: 992px) {
      position: unset;
      right: unset;
      bottom: unset;
      margin: 70px auto 0;
    }

  & img {
    transition: transform 0.3s ease-out;
  }

  &:hover {
    img {
      transform: translateX(-10px);
    }
  }
}

#step2, #step3 {
  padding: 30px;

  @media (max-width: 992px) {
    padding: 20px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;

    @media (max-width: 992px) {
      margin-bottom: 0;
    }

    .logo {
      height: 65px;
      cursor: pointer;
    }

    .help {
      cursor: pointer;
    }
    
    @media (max-width: 992px) {
      .help {
        margin-right: 15px;
      }
    }

    

    // &:after {
    //   content: '';
    //   width: 83%;
    //   display: inline-block;
    //   height: 1px;
    //   background: #000;
    //   position: absolute;
    //   right: 0;
    //   left: 0;
    //   margin: 0 auto;
    //   bottom: 0;
    // }
  }

  .main-title {
    margin: -50px 0 30px;
    text-align: center;

    @media (max-width: 992px) {
      margin: 20px 0 20px;
    } 
  }
}

#step2 {
  .range {
    max-width: 960px;
    width: 67%;
    margin: 0 auto;
  }

  .rotate-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .rotate-btn {
      width: 42px;
      height: 42px;
      background: #4A7EBD;
      cursor: pointer;
      color: white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
      &:last-child {
        margin-right: 0;
      }

      &.left {
        transform: rotate(0);
      }

      &.right {
        transform: scaleX(-1);
      }

      &:hover,
      &:focus-visible {
        color: #4A7EBD;
        background-color: white;
        box-shadow: inset 0 0 0 1px #4A7EBD;
      }
    }
  }
  
  .template {
    position: relative;
    // max-width: 960px;
    // width: 67%;
    width: 877px;
    margin: 0 auto;
    img {
      position: relative;
      max-width: 100%;
    }

    .js-clipper-fixed {
      position: absolute;
      // top: 15.52%;
      // left: 15.52%;
      // width: 68.96%;
      // height: 68.95%;

      // top: 95.25px;
      // left: 136px;
      // width: 605.75px;
      // height: 429.5px;

      top: 91px;
      left: 178px;
      width: 523px;
      height: 372px;
    }

    @media (max-width: 992px) {
      width: 100%;
      img {
        display: none;
      }
      .js-clipper-fixed {
        position: relative;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    .btn {
      box-sizing: border-box;
      border: none;
      padding: 10px;
      background: #4A7EBD;
      cursor: pointer;
      color: white;
      margin-right: 10px;
      text-align: center;
      border-radius: 100px;
      width: 200px;
      transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
      &:last-child {
        margin-right: 0;
      }
      &:disabled {
        opacity: 0.5;
      }

      &:hover,
      &:focus-visible {
        color: #4A7EBD;
        background-color: white;
        box-shadow: inset 0 0 0 1px #4A7EBD;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      width: 100%;
      .btn {
        margin: 0 0 10px 0;
        padding: 10px;
        width: 60%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

#step3 {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h3 {
    font-size: 36px;
    line-height: 46px;
    margin-top: 0;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  h4 {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 10px 0;
    text-align: center;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  img.result {
    max-width: 500px;
    width: 100%;
    cursor: pointer;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    .btn {
      box-sizing: border-box;
      border: none;
      padding: 10px 10px;
      background-color: #4A7EBD;
      cursor: pointer;
      color: white;
      margin-bottom: 20px;
      text-align: center;
      text-decoration: none;
      border-radius: 100px;
      transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
      &:last-child {
        margin-bottom: 0;
      }

      &:hover,
      &:focus-visible {
        color: #4A7EBD;
        background-color: white;
        box-shadow: inset 0 0 0 1px #4A7EBD;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      width: 100%;
      .btn {
        margin: 0 0 10px 0;
        padding: 10px;
        width: 60%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .repeat {
    position: relative;
    color: rgba(74, 126, 189, 1);
    text-align: center;
    text-decoration: none;
    max-width: 120px;
    margin: 0 auto;

    &:before {
      content: "";
      position: absolute;
      background-color: rgba(74, 126, 189, 1);
      left: 0;
      bottom: -3px;
      width: 0;
      height: 1px;
      transition: width 0.3s ease-in-out;
    }

    &:hover:before {
      width: 100%;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  text-align: justify;
  ol {
    padding-left: 20px;
  }
  a {
    display: block;
    text-align: center;
    color: rgba(74, 126, 189, 1);
  }
}

.hint {
  max-width: 450px;
  width: 90%;
  text-align: justify;
  opacity: 0.5;
  padding-top: 15px;
  font-size: 75%;
  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    background: black;
    margin-bottom: 5px;
  }
}

.modal-title {
    font-size: 42px;
    line-height: 68px;
    font-weight: 700;
  }

.modal-close {
  cursor: pointer;
  position: absolute;
  font-size: 26px;
  line-height: 26px;
  width: 26px;
  padding: 5px;
  right: 10px;
  top: 10px;
  text-align: center;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  z-index: 10;
  color: #000;
  text-decoration: none;
}

$preloaderMainColor: #0053a6;
$preloaderSecondColor: #E85412;
$preloaderWidth: 55px;
$preloaderHeight: $preloaderWidth;
/*$preloaderAnimationDuration: 1.3s;
$preloaderAnimationDelay: 30; //ms*/
/* baidin */
$preloaderAnimationDuration: 1.7s;
$preloaderAnimationDelay: 150; //ms
/* series */
/*$preloaderAnimationDuration: .9s;
$preloaderAnimationDelay: 100; //ms*/

.preloader {
  width: $preloaderWidth;
  height: $preloaderHeight;
  margin: 20px auto;
  overflow: hidden;
  
  div {
    padding: 0;
    margin: 0;
    display: block;
    float: left;
    width: 27%;
    height: 27%;
    margin-right: 9%;
    margin-bottom: 9%;
    background-color: $preloaderMainColor;
    
    &:nth-child(3n){
      margin-right: 0;
    }
    
    span {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0;
      background-color: $preloaderSecondColor;
      content: '';
      animation-duration: $preloaderAnimationDuration;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }
    
    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        span{
          animation-delay: #{$i * $preloaderAnimationDelay}ms;
        }
      }
    }
  }
}

.preloader--gradient {
  // margin-left: calc($preloaderWidth / 2);
  div {
    span {
      animation-name: gradient;
    }
  }
}

.print-instruction-btn {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer;
}

@keyframes gradient {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}
</style>
